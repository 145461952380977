import React from 'react';
import MainLayout from '../layouts/main';
import { Typography } from '@material-ui/core';
import styled from '../styled';
import PageHead from '../components/PageHead';
import { SEO } from '../components/SEO';
import { Label } from '../components/Section';
import { Link } from 'gatsby';

const StyledLink = styled(Link)`
  color: ${(p) => p.theme.palette.primary.main};
  border-bottom: 1px solid;
`;

const LogosWrapper = styled('div')`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: ${(p) => p.theme.spacing(3)}px;
  grid-row-gap: ${(p) => p.theme.spacing(3)}px;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const LogoContainer = styled('div')`
  position: relative;
  display: grid;
  align-items: center;
  justify-content: center;
  border: 1px solid #f5f4f4;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.03);
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  padding: ${(p) => p.theme.spacing(8)}px ${(p) => p.theme.spacing(4)}px;
`;

const Integrations = styled('div')`
  text-align: center;
  width: 100%;
  margin: 0 auto;
  color: #333;
  max-width: 1000px;
  width: 80%;
  padding: 24px;
`;

const Headline = styled<typeof Typography>(Typography)`
  max-width: 800px;
  margin: 0 auto ${(p) => p.theme.spacing(3)}px;
  width: 90%;

  @media (max-width: 1200px) {
    font-size: ${(p) => p.theme.typography.h5.fontSize}px;
    line-height: ${(p) => p.theme.typography.h5.lineHeight};
    margin: 0 auto ${(p) => p.theme.spacing(2)}px;
  }
`;

export default () => (
  <MainLayout>
    <div>
      <SEO
        pathname="/partners/"
        title="Partner with Us"
        description="Partner with Affilimate as an integrator, publisher partner, or affiliate promoter."
      />
      <PageHead style={{ marginBottom: 0 }}>
        <Headline variant="h4" component="h1" style={{ fontWeight: 900 }}>
          Partner with Affilimate
        </Headline>
        <Typography
          paragraph
          style={{ maxWidth: '500px', padding: '24px', margin: '0 auto' }}
        >
          Affilimate gives publishers the data they need to grow their affiliate
          revenue (and advertisers', too).
        </Typography>
      </PageHead>
      <Integrations style={{ marginBottom: '24px' }}>
        <div style={{ marginBottom: '48px' }}>
          <LogosWrapper>
            <LogoContainer>
              <Label>Partner with Us</Label>
              <Typography variant="body2" color="textSecondary">
                Explore partnership opportunities with us by{' '}
                <StyledLink to="/contact/">
                  filling out our contact form
                </StyledLink>
              </Typography>
            </LogoContainer>
            <LogoContainer>
              <Label>Promote Us</Label>
              <Typography variant="body2" color="textSecondary">
                Teach your audience how to grow their revenue with Affilimate by{' '}
                <StyledLink
                  to="/qs/affiliate-program/"
                  style={{ borderBottom: '1px solid' }}
                >
                  joining our affiliate program
                </StyledLink>
              </Typography>
            </LogoContainer>
          </LogosWrapper>
        </div>
      </Integrations>
    </div>
  </MainLayout>
);
